import React, { Component } from "react";
import FlatHeader from "../common/FlatHeader";
import ReactGA from "react-ga";

class Contact extends Component {
  constructor(props) {
    ReactGA.initialize("UA-109059503-2");
    ReactGA.pageview("/contact");

    super(props);
    this.state = {
      value: { min: 2, max: 10 }
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <FlatHeader title="Contact" />

        <section id="contact-us" className="iq-full-contact white-bg">
          <div className="container-fluid">
            <div className="row no-gutter">
              <div className="col-sm-5">
                <div className="iq-map">
                  <iframe
                    width="100%"
                    height={600}
                    src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=510%2C%20Addor%20Aspire%2C%20B%2Fs%20Jhanvee%20Restaurant%2C%20Nr.%20Passport%20Office%2C%20Polytechnic%20Rd%2C%20University%20Area%2C%20Ahmedabad%2C%20Gujarat%20380015+(Vytech%20Enterprise)&ie=UTF8&t=k&z=17&iwloc=B&output=embed"
                    frameBorder={0}
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                  />
                </div>
              </div>
              <div className="col-sm-7 iq-mt-20 iq-pall-40">
                <div>
                  <h4
                    className="heading-left iq-tw-6 iq-pb-20"
                    style={{ marginLeft: "10%" }}
                  >
                    Get in Touch
                  </h4>
                  <div className="row">
                    <div id="formmessage">Success/Error Message Goes Here</div>
                    <form
                      className="form-horizontal"
                      id="contactform"
                      method="post"
                      action="https://iqonicthemes.com/themes/appino/wave/php/contact-form.php"
                    >
                      <div className="contact-form">
                        <div className="col-sm-1" />
                        <div className="col-sm-5">
                          <div
                            className="section-field"
                            style={{ marginLeft: "2%" }}
                          >
                            <input
                              id="name"
                              type="text"
                              placeholder="Name*"
                              name="name"
                            />
                          </div>
                          <div
                            className="section-field"
                            style={{ marginLeft: "2%" }}
                          >
                            <input
                              type="email"
                              placeholder="Email*"
                              name="email"
                            />
                          </div>
                          <div
                            className="section-field"
                            style={{ marginLeft: "2%" }}
                          >
                            <input
                              type="text"
                              placeholder="Website*"
                              name="phone"
                            />
                          </div>

                          <div style={{ marginLeft: "2%" }}>
                            <select className="dropopt">
                              <option selected>
                                Select Your Interested Area
                              </option>
                              <option>Website Development</option>
                              <option>Mobile Application Development</option>
                              <option>Software Solutions</option>
                              <option>Branding</option>
                              <option>Digital Marketing</option>
                            </select>
                          </div>

                          <div
                            className="section-field"
                            style={{ marginLeft: "2%" }}
                          >
                            <input
                              type="text"
                              placeholder="Estimated Budget*"
                              name="phone"
                            />
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <div
                            className="section-field"
                            style={{ marginLeft: "2%" }}
                          >
                            <input
                              type="text"
                              placeholder="Phone*"
                              name="phone"
                            />
                          </div>
                          <div
                            className="section-field"
                            style={{ marginLeft: "2%" }}
                          >
                            <input
                              type="text"
                              placeholder="Company Name*"
                              name="phone"
                            />
                          </div>

                          <div
                            className="section-field textarea"
                            style={{ marginLeft: "2%" }}
                          >
                            <textarea
                              className="input-message"
                              placeholder="Message*"
                              rows={7}
                              name="message"
                              defaultValue={""}
                            />
                          </div>
                          <input
                            type="hidden"
                            name="action"
                            defaultValue="sendEmail"
                          />
                          <button
                            id="submit"
                            name="submit"
                            type="submit"
                            value="Send"
                            className="button pull-right iq-mt-40"
                          >
                            Send Message
                          </button>
                        </div>
                        <div className="col-sm-1" />
                      </div>
                    </form>
                    <div id="ajaxloader" style={{ display: "none" }}>
                      <img
                        className="center-block mt-30 mb-30"
                        src="images/ajax-loader.html"
                        alt
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="container">
            <div className="row iq-ptb-80">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="iq-fancy-box-04">
                  <div className="iq-icon green-bg">
                    <i
                      aria-hidden="true"
                      className="ion-ios-location-outline"
                    />
                  </div>
                  <div className="fancy-content">
                    <h5 className="iq-tw-6">Address</h5>
                    <span className="lead iq-tw-6">
                      510, Addor Aspire, B/s Jhanvee Restaurant, Nr. Passport
                      Office, Polytechnic Rd, Ahmedabad, Gujarat 380015
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="iq-fancy-box-04">
                  <div className="iq-icon green-bg">
                    <i
                      aria-hidden="true"
                      className="ion-ios-telephone-outline"
                    />
                  </div>
                  <div className="fancy-content">
                    <h5 className="iq-tw-6">Phone</h5>
                    <span className="lead iq-tw-6">+91 7926300990</span>
                    <p className="iq-mb-0">Mon-Sat 9:30am - 6:30pm</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="iq-fancy-box-04">
                  <div className="iq-icon green-bg">
                    <i aria-hidden="true" className="ion-ios-email-outline" />
                  </div>
                  <div className="fancy-content">
                    <h5 className="iq-tw-6">Mail</h5>
                    <span className="lead iq-tw-6">
                      <a
                        href="https://iqonicthemes.com/cdn-cgi/l/email-protection"
                        className="__cf_email__"
                        data-cfemail="e68b878f8aa68796968f8889c885898b"
                      >
                        info@vytechenterprise.com
                      </a>
                    </span>
                    <p className="iq-mb-0">24 X 7 online support</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
