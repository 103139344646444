import React from "react";
import Link from "react-router-dom/es/Link";

const TeamMember = ({ title, image, portfolio_id, service_category_id }) => {
  return (
    <div>
      <li className={"project" + " option-" + service_category_id}>
        <div className="project-image">
          <img src={image} alt="Sports Image" />
        </div>
        <div className="project-info">
          <h2 className="project-info-title" style={{ color: "#fff" }}>
            {title}
          </h2>
          <Link
            className="project-info-button"
            to={"/portfolio-details/" + portfolio_id}
          >
            View
          </Link>
        </div>
      </li>
    </div>
  );
};

export default TeamMember;
