import React from "react";

import android from "../../assets/images/banner/new/android.png";
import codeigniter from "../../assets/images/banner/new/codeigniter.png";
import react from "../../assets/images/banner/new/react.png";
import ios from "../../assets/images/banner/new/ios.png";
import html from "../../assets/images/banner/new/html.png";
import yii2 from "../../assets/images/banner/new/yii.png";
import mobile_mockup from "../../assets/images/banner/Vytech-Logo-For-New-Website.gif";
import Background from "../../assets/images/banner/bg.jpg";
import Plx from "react-plx";

const HeroSection = () => {
  const androidParallaxDataL = [
    {
      start: 0,
      duration: 100,
      properties: [
        {
          startValue: -250,
          endValue: -400,
          property: "translateX"
        }
      ]
    }
  ];

  const iosParallaxDataR = [
    {
      start: 0,
      duration: 100,
      properties: [
        {
          startValue: -200,
          endValue: -50,
          property: "translateX"
        }
      ]
    }
  ];

  const codeigniterParallaxDataL = [
    {
      start: 0,
      duration: 100,
      properties: [
        {
          startValue: 150,
          endValue: 30,
          property: "translateX"
        }
      ]
    }
  ];

  const htmlParallaxDataR = [
    {
      start: 0,
      duration: 100,
      properties: [
        {
          startValue: -200,
          endValue: -50,
          property: "translateX"
        }
      ]
    }
  ];

  const reactParallaxDataL = [
    {
      start: 0,
      duration: 100,
      properties: [
        {
          startValue: 150,
          endValue: 30,
          property: "translateX"
        }
      ]
    }
  ];

  const yiiParallaxDataR = [
    {
      start: 0,
      duration: 100,
      properties: [
        {
          startValue: -200,
          endValue: -50,
          property: "translateX"
        }
      ]
    }
  ];

  return (
    <div>
      <section
        id="iq-home"
        className="banner-03 iq-bg iq-bg-fixed iq-over-black-80"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="iq-font-white iq-tw-8 text-center">
                AMAZING DEVELOPMENT
                <small className="iq-font-white iq-tw-6">
                  <b className="iq-font-white">●</b>We provide full variety of
                  IT solutions.
                </small>
              </h1>
              <div className="iq-mobile-app text-center">
                <div className="iq-mobile-box">
                  <img className="iq-mobile-img" src={mobile_mockup} alt="#" />

                  <span
                    data-depth="0.8"
                    className="layer iq-mobile-icon icon-03"
                  >
                    <Plx parallaxData={androidParallaxDataL}>
                      <img src={android} className="disnon" alt="#" />
                    </Plx>
                  </span>

                  <span
                    data-depth="0.8"
                    className="layer iq-mobile-icon icon-03"
                  >
                    <Plx parallaxData={iosParallaxDataR}>
                      <img src={ios} className="disnon" alt="#" />
                    </Plx>
                  </span>

                  <span
                    data-depth="0.8"
                    className="layer iq-mobile-icon icon-02"
                    style={{ marginTop: 30, zIndex: 1 }}
                  >
                    <Plx parallaxData={codeigniterParallaxDataL}>
                      <img src={codeigniter} className="disnon" alt="#" />
                    </Plx>
                  </span>

                  <span
                    data-depth="0.8"
                    className="layer iq-mobile-icon icon-03"
                    style={{ marginTop: 180 }}
                  >
                    <Plx parallaxData={htmlParallaxDataR}>
                      <img src={html} className="disnon" alt="#" />
                    </Plx>
                  </span>

                  <span
                    data-depth="0.8"
                    className="layer iq-mobile-icon icon-02"
                    style={{ marginTop: 200, zIndex: 1 }}
                  >
                    <Plx parallaxData={reactParallaxDataL}>
                      <img src={react} className="disnon" alt="#" />
                    </Plx>
                  </span>

                  <span
                    data-depth="0.8"
                    className="layer iq-mobile-icon icon-03"
                    style={{ marginTop: 340, zIndex: 1 }}
                  >
                    <Plx parallaxData={yiiParallaxDataR}>
                      <img src={yii2} className="disnon" alt="#" />
                    </Plx>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wave1" />
      </section>
    </div>
  );
};

export default HeroSection;
