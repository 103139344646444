import React from 'react';

const heading = ({title, subTitle}) => {
    return (
        <div className="heading-title">
            <h2 className="title iq-tw-6">{title}</h2>
            <div className="divider"/>
            <p>{subTitle}</p>
        </div>
    );
};

export default heading;
