import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import About from "./components/about/About";
import Blog from "./components/blog/Blog";
import BlogDetails from "./components/blog/blog_desc";
import Contact from "./components/contact/Contact";
import Methodology from "./components/methodology/Methodology";
import Portfolio from "./components/portfolio/Portfolio";
import PortfolioDetails from "./components/portfolio/Portfolio-description";
import Services from "./components/services/Services";
import Footer from "./components/layout/Footer";

import logo from "./logo.svg";
import "./App.css";
import "./assets/css/animate.css";

/* Loading css files */
import "./assets/css/animate.css";
// import '../../assets/css/bootstrap.min.css'
import "./assets/css/custom.css";
// import '../../assets/css/font-awesome.css'
import "./assets/css/ionicons.min.css";
import "./assets/css/laptop.css";
import "./assets/css/responsive.css";
import "./assets/css/style.scss";
import "./assets/css/style-box.css";
import "./assets/css/style-customizer.css";
import "./assets/css/services_page.css";

import "./assets/css/style2745-old.css";

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <div id="loading" className="green-bg">
            <div id="loading-center">
              <div className="boxLoading" />
            </div>
          </div>

          {/* <div id="loading-center">
              <img src={preloader} alt="DesignbyANB" />
          </div> */}

          <Navbar />

          <Switch>
            <Route exact path="/" component={Landing} />
            <Route path="/about" component={About} />
            <Route path="/methodology" component={Methodology} />
            <Route path="/services" component={Services} />
            <Route path="/portfolio" component={Portfolio} />
            <Route
              path="/portfolio-details/:portfolio_id"
              component={PortfolioDetails}
            />
            <Route path="/blog" component={Blog} />
            <Route path="/blog-details/:id" component={BlogDetails} />
            <Route path="/contact" component={Contact} />
          </Switch>

          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
