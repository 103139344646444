import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="iq-footer-03 white-bg iq-ptb-20">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="footer-copyright iq-pt-10">
                  &#169;&nbsp;Copyright&nbsp;2014&nbsp;
                  <span id="copyright" />
                  <a href="javascript:void(0)" className="text-green">
                    Vytech Enterprise
                  </a>{" "}
                  All Rights Reserved
                  <br />
                  Design By Vytech Enterprise Team
                </div>
              </div>
              <div className="col-sm-6">
                <ul className="info-share">
                  <li>
                    <a
                      href="https://www.instagram.com/vytechenterprise/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/vytech/" target="_blank">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/vytechenterprise"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@vytechenterprise.com" target="_blank">
                      <i className="fa fa-google" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>

        <div id="back-to-top">
          <a className="top" id="top" href="#top">
            {" "}
            <i className="ion-ios-upload-outline" />{" "}
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
