import React, { Component } from "react";
import FlatHeader from "../common/FlatHeader";
import ReactGA from "react-ga";
import axios from "axios";

class PortfolioDescription extends Component {
  constructor() {
    ReactGA.initialize("UA-109059503-2");
    ReactGA.pageview("/portfolio-details");
    super();
    this.state = {
      singleportfolio: null,
      iosX: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    axios
      .get(
        "https://api.vytech.co/ws/get-single-portfolio?portfolio_id=" +
          this.props.match.params.portfolio_id
      )
      .then(res => this.setState({ singleportfolio: res.data.data }))
      .catch(err => console.log(err));
  }

  render() {
    const { singleportfolio } = this.state;
    return (
      <div>
        <FlatHeader title="Portfolio" />

        <div className="work-template-default single single-work postid-63 mcook">
          <div className="wrap">
            <div className="paze">
              <div className="ss-container">
                <h2 className="title123 title123--primary">
                  {singleportfolio !== null ? singleportfolio.title : null}
                </h2>
                <div className="paze__data">
                  <div className="paze__content">
                    <div className="entry-content">
                      {singleportfolio !== null
                        ? singleportfolio.multiple_images.map(
                            (image, index) => <img key={index} src={image} />
                          )
                        : null}
                    </div>
                  </div>
                  <div className="paze__sidebar">
                    <div className="sidebar">
                      <div className="sidebar__widget about-project">
                        <div className="sidebar__card">
                          <h4 className="sidebar__title">About</h4>
                          <p className="sidebar__desc" />
                          <p style={{ textAlign: "justify" }}>
                            {singleportfolio !== null
                              ? singleportfolio.description
                              : null}
                          </p>
                          <p />
                        </div>
                      </div>

                      {/* <div className="sidebar__widget next">
                        <a className="sidebar__card" href="mobileapp2.html">
                          <h4 className="sidebar__title">Filmy Keeday</h4>
                          <p className="sidebar__desc">
                            An entertainment portal for cinema lovers
                          </p>
                        </a>
                      </div>
                      <div className="sidebar__widget prev">
                        <a className="sidebar__card" href="mobileapp1.html">
                          <h4 className="sidebar__title">MoviesDrop</h4>
                          <p className="sidebar__desc">
                            A community for Movies, TV Series &amp;
                            Documentaries
                          </p>
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioDescription;
