import React, { Component } from "react";

import uiux from "../../assets/images/screenshots/specialservice1.png";
import mobiledev from "../../assets/images/screenshots/specialservice2.png";
import softsolu from "../../assets/images/screenshots/specialservice3.png";
import webdev from "../../assets/images/screenshots/specialservice4.png";
import graphdes from "../../assets/images/screenshots/specialservice5.png";
import digimar from "../../assets/images/screenshots/specialservice6.png";

import img_001 from "../../assets/images/iphone-about.png";
import FlatHeader from "../common/FlatHeader";
// import ReactGA from "react-ga";

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <FlatHeader title="About Us" />
        <div className="main-content">
          <section id="about-us" className="overview-block-ptb iq-mt-50">
            <div className="container">
              <div className="row">
                {/*<div className="col-sm-12">*/}
                {/*<div className="heading-title">*/}
                {/*<h2 className="title iq-tw-6">About us</h2>*/}
                {/*<div className="divider" />*/}
                {/*<p>*/}
                {/*We've been working really hard to improve the appino with*/}
                {/*this amazing new features that you aked for! Check out the*/}
                {/*appino’s new amazing features.{" "}*/}
                {/*</p>*/}
                {/*</div>*/}
                {/*</div>*/}
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="iq-fancy-box-03 text-left">
                    <i
                      aria-hidden="true"
                      className="ion-ios-pie-outline iq-font-blue"
                    />
                    <h4
                      className="iq-tw-6"
                      style={{ fontSize: "1.5em!important" }}
                    >
                      Web Development
                    </h4>
                    <div className="info-03">
                      <p style={{ textAlign: "justify" }}>
                        We have a crystal clear objective to deliver quality IT
                        services and Software Development Services while
                        incorporating supreme value to enterprise globally at
                        affordable prices. In order to provide world class IT
                        solutions, We have taliored our In-House proven
                        methodology, which helps us provide reliable IT
                        solutions that meet present as well as future demands.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 re7-mt-30">
                  <div className="iq-fancy-box-03 text-left">
                    <i
                      aria-hidden="true"
                      className="ion-ios-color-filter-outline iq-font-blue"
                    />
                    <h4
                      className="iq-tw-6"
                      style={{ fontSize: "1.5em!important" }}
                    >
                      App Development
                    </h4>
                    <div className="info-03">
                      <p style={{ textAlign: "justify" }}>
                        Vytech Enterprise is a global leader in providing mobile
                        application development services. We are leading_edge
                        mobile application development company with over 60
                        successful projects under its banner.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 re7-mt-30">
                  <div className="iq-fancy-box-03 text-left">
                    <i
                      aria-hidden="true"
                      className="ion-ios-gear-outline iq-font-blue"
                    />
                    <h4
                      className="iq-tw-6"
                      style={{ fontSize: "1.5em!important" }}
                    >
                      Graphics Design
                    </h4>
                    <div className="info-03">
                      <p style={{ textAlign: "justify" }}>
                        To develop the ideal User interface, Vytech Enterprise
                        offers user interface and design and development
                        solutions in Web, Desktop and developers built custom
                        applications which are user-friendly and realistic. In
                        order to get more leads, websites rely on the latest
                        features and interactive designs. The field is
                        considered a subset of visual communication and design.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr />

          <section
            id="about-us-01"
            className="overview-block-ptb iq-about-1 grey-bg"
          >
            <div className="container">
              <div className="row iq-mt-20">
                <div className="col-sm-12 col-md-6 col-lg-6 iq-mt-30 popup-gallery">
                  <h2 className="heading-left iq-tw-6 iq-mt-40">Who we are</h2>
                  <p style={{ textAlign: "justify" }}>
                    We have a crystal clear objective to deliver quality IT
                    services and Software Development Services while
                    incorporating supreme value to enterprises globally at
                    affordable prices. In order to provide world class IT
                    solutions,We Have tailored our in-house proven methodology,
                    which helps us provide reliable IT solutions that meet
                    present as well as future demands. Our commitment to
                    regularly improve and better on our products sets us apart
                    from the rest.
                  </p>
                  <div className="btn-group iq-mt-40" role="group" aria-label>
                    {/* <a class="button popup-youtube" href="video/01.mp4">Watch Video Demo</a> */}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <img
                    className="img-responsive center-block"
                    src={img_001}
                    alt="#"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            id="features"
            className="overview-block-ptb iq-amazing-tab white-bg"
          >
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="heading-title">
                    <h2 className="title iq-tw-6">Special Services</h2>
                    <div className="divider" />
                    <p>
                      The quality of any work depends on the sources and
                      infrastructure available with provides.Vytech houses 50+
                      well experienced experts in different domains such as
                      research, business analysis, project management,
                      programming, database administration, crative designing
                      and quality control!
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <ul className="nav nav-tabs" role="tablist">
                    <li
                      role="presentation"
                      className="active wow fadeInLeft"
                      data-wow-duration="1s"
                    >
                      <a
                        className="round-right"
                        href="#design"
                        aria-controls="design"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01 text-right">
                          <i aria-hidden="true" className="ion-ipad" />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            UI/UX Design
                          </h4>
                          <div className="fancy-content-01">
                            <p style={{ textAlign: "justify" }}>
                              Innovation and User Friendliness always walks Hand
                              in Hand and so does UI/UX.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className="wow fadeInLeft"
                      data-wow-duration="1s"
                    >
                      <a
                        className="round-right"
                        href="#resolution"
                        aria-controls="resolution"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01 text-right">
                          <i
                            aria-hidden="true"
                            className="ion-android-desktop"
                          />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            Web Development
                          </h4>
                          <div className="fancy-content-01">
                            <p style={{ textAlign: "justify" }}>
                              In today’s World Your Website is your new store,
                              and we know how to make it magnificent and eye
                              catching.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className="wow fadeInLeft"
                      data-wow-duration="1s"
                    >
                      <a
                        className="round-right"
                        href="#ready"
                        aria-controls="ready"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01 text-right">
                          <i
                            aria-hidden="true"
                            className="ion-android-phone-portrait"
                          />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            Mobile App Development
                          </h4>
                          <div className="fancy-content-01">
                            <p style={{ textAlign: "justify" }}>
                              Mobile Apps have the power to ease your life with
                              just a Tap, hence its important to Develop them
                              with minute detailing.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 text-center hidden-sm hidden-xs">
                  <div className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="design"
                    >
                      <img
                        src={uiux}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                    <div role="tabpanel" className="tab-pane" id="resolution">
                      <img
                        src={webdev}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                    <div role="tabpanel" className="tab-pane" id="ready">
                      <img
                        src={mobiledev}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                    <div role="tabpanel" className="tab-pane" id="fertures">
                      <img
                        src={softsolu}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                    <div role="tabpanel" className="tab-pane" id="face">
                      <img
                        src={graphdes}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                    <div role="tabpanel" className="tab-pane" id="codes">
                      <img
                        src={digimar}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <ul className="nav nav-tabs" role="tablist">
                    <li
                      role="presentation"
                      className="wow fadeInRight"
                      data-wow-duration="1s"
                    >
                      <a
                        href="#fertures"
                        aria-controls="fertures"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01">
                          <i
                            aria-hidden="true"
                            className="ion-android-laptop"
                          />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            Software Solution
                          </h4>
                          <div className="fancy-content-01">
                            <p style={{ textAlign: "justify" }}>
                              We Provide Software Solutions Which Runs faster.
                              Costs less. And never breaks.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className="wow fadeInRight"
                      data-wow-duration="1s"
                    >
                      <a
                        href="#face"
                        aria-controls="face"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01">
                          <i
                            aria-hidden="true"
                            className="ion-social-designernews"
                          />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            Graphic Designing
                          </h4>
                          <div className="fancy-content-01">
                            <p style={{ textAlign: "justify" }}>
                              A well Designed Graphic can speak more than 1000
                              words, and convey all its sense without any words.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className="wow fadeInRight"
                      data-wow-duration="1s"
                    >
                      <a
                        href="#codes"
                        aria-controls="codes"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01">
                          <i aria-hidden="true" className="ion-volume-mute" />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            Digital Marketing
                          </h4>
                          <div className="fancy-content-01">
                            <p style={{ textAlign: "justify" }}>
                              Instead of one-way interruption,Digital marketing
                              is about delivering useful content at just the
                              right moment that buyer needs it.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/*<section id="team" className="overview-block-ptb white-bg">*/}
          {/*<div className="container">*/}
          {/*<div className="row">*/}
          {/*<div className="col-sm-12">*/}
          {/*<div className="heading-title">*/}
          {/*<h2 className="title iq-tw-6">Meet the Team</h2>*/}
          {/*<div className="divider"/>*/}
          {/*<p>Meet the faces behind our company. Our team offers the most up-to-date,*/}
          {/*sustainable custom solutions to the problem. They work so hard to provide best*/}
          {/*to their customers.</p>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="row">*/}
          {/*<div className="col-sm-6 col-lg-3 col-md-3">*/}
          {/*<div className="iq-team">*/}
          {/*<img src={team_01} className="img-responsive center-block" alt="#"/>*/}
          {/*<div className="iq-team-info text-center">*/}
          {/*<h4 className="iq-font-white iq-tw-5">Chirag Vyas</h4>*/}
          {/*<span className="team-post iq-tw-5">CEO</span>*/}
          {/*<div className="share iq-mt-15">*/}
          {/*<span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"/> Share With</span>*/}
          {/*<nav>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-twitter"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-facebook"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-google"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-github"/></a>*/}
          {/*</nav>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="col-sm-6 col-lg-3 col-md-3">*/}
          {/*<div className="iq-team re7-mt-50">*/}
          {/*<img src={team_04} className="img-responsive center-block" alt="#"/>*/}
          {/*<div className="iq-team-info text-center">*/}
          {/*<h4 className="iq-font-white iq-tw-5">Varun Vyas</h4>*/}
          {/*<span className="team-post iq-tw-5">CTO</span>*/}
          {/*<div className="share iq-mt-15">*/}
          {/*<span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"/> Share With</span>*/}
          {/*<nav>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-twitter"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-facebook"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-google"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-github"/></a>*/}
          {/*</nav>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="col-sm-6 col-lg-3 col-md-3 re-mt-30">*/}
          {/*<div className="iq-team">*/}
          {/*<img src={team_03} className="img-responsive center-block" alt="#"/>*/}
          {/*<div className="iq-team-info text-center">*/}
          {/*<h4 className="iq-font-white iq-tw-5">Soham Bhatt</h4>*/}
          {/*<span className="team-post iq-tw-5">Android/ios Developer</span>*/}
          {/*<div className="share iq-mt-15">*/}
          {/*<span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"/> Share With</span>*/}
          {/*<nav>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-twitter"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-facebook"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-google"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-github"/></a>*/}
          {/*</nav>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="col-sm-6 col-lg-3 col-md-3 re-mt-30">*/}
          {/*<div className="iq-team">*/}
          {/*<img src={team_04} className="img-responsive center-block" alt="#"/>*/}
          {/*<div className="iq-team-info text-center">*/}
          {/*<h4 className="iq-font-white iq-tw-5">Vishal Shah</h4>*/}
          {/*<span className="team-post iq-tw-5">Graphic Designer</span>*/}
          {/*<div className="share iq-mt-15">*/}
          {/*<span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"/> Share With</span>*/}
          {/*<nav>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-twitter"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-facebook"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-google"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-github"/></a>*/}
          {/*</nav>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="row" style={{paddingTop: '5%'}}>*/}
          {/*<div className="col-sm-6 col-lg-3 col-md-3">*/}
          {/*<div className="iq-team">*/}
          {/*<img src={team_04} className="img-responsive center-block" alt="#"/>*/}
          {/*<div className="iq-team-info text-center">*/}
          {/*<h4 className="iq-font-white iq-tw-5">Anil Ram</h4>*/}
          {/*<span className="team-post iq-tw-5">Developer</span>*/}
          {/*<div className="share iq-mt-15">*/}
          {/*<span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"/> Share With</span>*/}
          {/*<nav>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-twitter"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-facebook"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-google"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-github"/></a>*/}
          {/*</nav>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="col-sm-6 col-lg-3 col-md-3">*/}
          {/*<div className="iq-team re7-mt-50">*/}
          {/*<img src={team_04} className="img-responsive center-block" alt="#"/>*/}
          {/*<div className="iq-team-info text-center">*/}
          {/*<h4 className="iq-font-white iq-tw-5">Himansu Tank</h4>*/}
          {/*<span className="team-post iq-tw-5">Developer</span>*/}
          {/*<div className="share iq-mt-15">*/}
          {/*<span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"/> Share With</span>*/}
          {/*<nav>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-twitter"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-facebook"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-google"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-github"/></a>*/}
          {/*</nav>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="col-sm-6 col-lg-3 col-md-3 re-mt-30">*/}
          {/*<div className="iq-team">*/}
          {/*<img src={team_03} className="img-responsive center-block" alt="#"/>*/}
          {/*<div className="iq-team-info text-center">*/}
          {/*<h4 className="iq-font-white iq-tw-5">Akash Bambhaniya</h4>*/}
          {/*<span className="team-post iq-tw-5">Web Developer</span>*/}
          {/*<div className="share iq-mt-15">*/}
          {/*<span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"/> Share With</span>*/}
          {/*<nav>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-twitter"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-facebook"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-google"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-github"/></a>*/}
          {/*</nav>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="col-sm-6 col-lg-3 col-md-3 re-mt-30">*/}
          {/*<div className="iq-team">*/}
          {/*<img src={team_04} className="img-responsive center-block" alt="#"/>*/}
          {/*<div className="iq-team-info text-center">*/}
          {/*<h4 className="iq-font-white iq-tw-5">Mitul Bhatt</h4>*/}
          {/*<span className="team-post iq-tw-5">DM Executive</span>*/}
          {/*<div className="share iq-mt-15">*/}
          {/*<span> <i className="fa fa-share-alt iq-mr-10" aria-hidden="true"/> Share With</span>*/}
          {/*<nav>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-twitter"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-facebook"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-google"/></a>*/}
          {/*<a href="javascript:void(0)"><i className="fa fa-github"/></a>*/}
          {/*</nav>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</section>*/}
          <hr />
          <section id="contact-us" className="iq-full-contact white-bg">
            <div className="container">
              <div className="row iq-ptb-80">
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="iq-fancy-box-04">
                    <div className="iq-icon green-bg">
                      <i
                        aria-hidden="true"
                        className="ion-ios-location-outline"
                      />
                    </div>
                    <div className="fancy-content">
                      <h5 className="iq-tw-6">Address</h5>
                      <span className="lead iq-tw-6">
                        510, Addor Aspire, B/s Jhanvee Restaurant, Nr. Passport
                        Office, Polytechnic Rd, Ahmedabad, Gujarat 380015
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="iq-fancy-box-04">
                    <div className="iq-icon green-bg">
                      <i
                        aria-hidden="true"
                        className="ion-ios-telephone-outline"
                      />
                    </div>
                    <div className="fancy-content">
                      <h5 className="iq-tw-6">Phone</h5>
                      <span className="lead iq-tw-6">+91 7926300900</span>
                      <p className="iq-mb-0">Mon-Sat 9:30am - 6:30pm</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="iq-fancy-box-04">
                    <div className="iq-icon green-bg">
                      <i aria-hidden="true" className="ion-ios-email-outline" />
                    </div>
                    <div className="fancy-content">
                      <h5 className="iq-tw-6">Mail</h5>
                      <span className="lead iq-tw-6">
                        <a
                          href="https://iqonicthemes.com/cdn-cgi/l/email-protection"
                          className="__cf_email__"
                          data-cfemail="e68b878f8aa68796968f8889c885898b"
                        >
                          info@vytechenterprise.com
                        </a>
                      </span>
                      <p className="iq-mb-0">24 X 7 online support</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default About;
