import React from "react";
import banner_bg from "../../assets/images/banner/bg.jpg";
import { Link } from "react-router-dom";

const FlatHeader = ({ title }) => {
  return (
    <div>
      <section
        className="iq-breadcrumb overview-block-pt text-center iq-bg iq-bg-fixed iq-over-black-90 iq-box-shadow"
        style={{ background: "url(" + banner_bg + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="heading-title iq-breadcrumb-title iq-mtb-100">
                <h1 className="title iq-tw-8 iq-font-white">{title}</h1>
                <div className="divider white" />
              </div>
              <ul className="breadcrumb">
                <li>
                  <Link to="/">
                    <i className="ion-home" /> Home
                  </Link>
                </li>
                <li className="active">{title}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FlatHeader;
