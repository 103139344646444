import React, { Component } from "react";
import FlatHeader from "../common/FlatHeader";
import $ from "jquery";
// import "../../assets/css/portfolio_css.css"
import "../../assets/css/portfolio.css";
import axios from "axios";
import ReactGA from "react-ga";
import PortfolioDesign from "../common/PortfolioDesign";
import Link from "react-router-dom/es/Link";

class Portfolio extends Component {
  constructor() {
    ReactGA.initialize("UA-109059503-2");
    ReactGA.pageview("/portfolio");
    super();
    this.state = {
      portdata: null,
      categories: null,
      iosX: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    $(document).ready(function() {
      $(".nav-item").click(function() {
        // reset active class
        $(".nav-item").removeClass("active");
        // add active class to selected
        $(this).addClass("active");
        // return needed to make function work
        return false;
      });

      $(function() {
        // create an empty variable
        var selectedClass = "";
        // call function when item is clicked
        $(".nav-item").click(function() {
          // assigns class to selected item
          selectedClass = $(this).attr("data-rel");
          // fades out all portfolio items
          $(".portfolio123 li").fadeOut(300);
          // fades in selected category
          $(".portfolio123 li." + selectedClass)
            .delay(300)
            .fadeIn(300);
        });
      });
    });

    axios
      .get("https://api.vytech.co/ws/get-categories")
      .then(res => this.setState({ categories: res.data.data }))
      .catch(err => console.log(err));

    axios
      .get("https://api.vytech.co/ws/get-portfolio")
      .then(res => this.setState({ portdata: res.data.data }))
      .catch(err => console.log(err));
  }

  render() {
    const { portdata, categories } = this.state;

    return (
      <div>
        <FlatHeader title="Portfolio" />
        {/* Portfolio */}
        <main className="main123" style={{ paddingTop: "5%" }}>
          {/* <nav className="nav123">
            {categories !== null
              ? categories.map((category, index) => (
                  <Link
                    key={index}
                    to={"/portfolio"}
                    className="nav-item"
                    data-rel={"option-" + category.service_category_id}
                  >
                    {category.name}
                  </Link>
                ))
              : null}
          </nav> */}
          <ul className="portfolio123">
            {portdata !== null
              ? portdata.map((member, index) => (
                  <PortfolioDesign
                    key={index}
                    title={member.title}
                    description={member.description}
                    image={member.image}
                    portfolio_id={member.portfolio_id}
                    service_category_id={member.category_id}
                  />
                ))
              : null}
          </ul>
        </main>
        {/*End Portfolio*/}
      </div>
    );
  }
}

export default Portfolio;

{
  /* old code */
}
// import React, { Component } from "react";
// import FlatHeader from "../common/FlatHeader";
// import $ from "jquery";
// // import "../../assets/css/portfolio_css.css"
// import "../../assets/css/portfolio.css";

// class Portfolio extends Component {
//   constructor() {
//     super();
//     this.state = {
//       portdata: null,

//       iosX: 0
//     };
//   }

//   componentDidMount() {
//     $(document).ready(function() {
//       $(".nav-item").click(function() {
//         // reset active class
//         $(".nav-item").removeClass("active");
//         // add active class to selected
//         $(this).addClass("active");
//         // return needed to make function work
//         return false;
//       });

//       $(function() {
//         // create an empty variable
//         var selectedClass = "";
//         // call function when item is clicked
//         $(".nav-item").click(function() {
//           // assigns class to selected item
//           selectedClass = $(this).attr("data-rel");
//           // fades out all portfolio items
//           $(".portfolio123 li").fadeOut(300);
//           // fades in selected category
//           $(".portfolio123 li." + selectedClass)
//             .delay(300)
//             .fadeIn(300);
//         });
//       });
//     });
//     axios
//     .get("https://api.vytech.co/ws/get-portfolio")
//     .then(res => this.setState({ portdata: res.data.data }))
//     .catch(err => console.log(err));
//   }

//   render() {
//     const { portdata } = this.state;

//     return (
//       <div>
//         <FlatHeader title="Portfolio123" />
//         {/* Portfolio */}
//         <main className="main123">
//           <nav className="nav123">
//             <a href="#" className="nav-item active" data-rel="project">
//               All
//             </a>
//             <a href="#" className="nav-item" data-rel="option-1">
//               Web Design
//             </a>
//             <a href="#" className="nav-item" data-rel="option-2">
//               Android App
//             </a>
//             <a href="#" className="nav-item" data-rel="option-3">
//               IOS App
//             </a>
//             <a href="#" className="nav-item" data-rel="option-3">
//               Digital Marketing
//             </a>
//           </nav>
//           <ul className="portfolio123">
//             <li className="project option-1">
//               <div className="project-image">
//                 <img
//                   src="http://lorempixel.com/400/400/sports/1/"
//                   alt="Sports Image"
//                 />
//               </div>
//               <div className="project-info">
//                 <h2 className="project-info-title">Cricket Tourney</h2>
//                 <a className="project-info-button" href="#">
//                   View
//                 </a>
//               </div>
//             </li>
//             <li className="project option-2">
//               <div className="project-image">
//                 <img
//                   src="http://lorempixel.com/400/400/sports/2/"
//                   alt="Sports Image"
//                 />
//               </div>
//               <div className="project-info">
//                 <h2 className="project-info-title">Surfing</h2>
//                 <a className="project-info-button" href="#">
//                   View
//                 </a>
//               </div>
//             </li>
//             <li className="project option-3">
//               <div className="project-image">
//                 <img
//                   src="http://lorempixel.com/400/400/sports/3/"
//                   alt="Sports Image"
//                 />
//               </div>
//               <div className="project-info">
//                 <h2 className="project-info-title">Tour De Force</h2>
//                 <a className="project-info-button" href="#">
//                   View
//                 </a>
//               </div>
//             </li>
//             <li className="project option-2">
//               <div className="project-image">
//                 <img
//                   src="http://lorempixel.com/400/400/sports/10/"
//                   alt="Sports Image"
//                 />
//               </div>
//               <div className="project-info">
//                 <h2 className="project-info-title">Baseball Season</h2>
//                 <a className="project-info-button" href="#">
//                   View
//                 </a>
//               </div>
//             </li>
//             <li className="project option-3">
//               <div className="project-image">
//                 <img
//                   src="http://lorempixel.com/400/400/sports/5/"
//                   alt="Sports Image"
//                 />
//               </div>
//               <div className="project-info">
//                 <h2 className="project-info-title">BMX Arrives</h2>
//                 <a className="project-info-button" href="#">
//                   View
//                 </a>
//               </div>
//             </li>
//             <li className="project option-1">
//               <div className="project-image">
//                 <img
//                   src="http://lorempixel.com/400/400/sports/6/"
//                   alt="Sports Image"
//                 />
//               </div>
//               <div className="project-info">
//                 <h2 className="project-info-title">World Cup 2016</h2>
//                 <a className="project-info-button" href="#">
//                   View
//                 </a>
//               </div>
//             </li>
//             <li className="project option-3">
//               <div className="project-image">
//                 <img
//                   src="http://lorempixel.com/400/400/sports/7/"
//                   alt="Sports Image"
//                 />
//               </div>
//               <div className="project-info">
//                 <h2 className="project-info-title">100 Meter Swim</h2>
//                 <a className="project-info-button" href="#">
//                   View
//                 </a>
//               </div>
//             </li>
//             <li className="project option-1">
//               <div className="project-image">
//                 <img
//                   src="http://lorempixel.com/400/400/sports/8/"
//                   alt="Sports Image"
//                 />
//               </div>
//               <div className="project-info">
//                 <h2 className="project-info-title">Kung Fu</h2>
//                 <a className="project-info-button" href="#">
//                   View
//                 </a>
//               </div>
//             </li>
//             <li className="project option-2">
//               <div className="project-image">
//                 <img
//                   src="http://lorempixel.com/400/400/sports/9/"
//                   alt="Sports Image"
//                 />
//               </div>
//               <div className="project-info">
//                 <h2 className="project-info-title">Women's Relay</h2>
//                 <a className="project-info-button" href="#">
//                   View
//                 </a>
//               </div>
//             </li>
//           </ul>
//         </main>
//         {/*End Portfolio*/}
//       </div>
//     );
//   }
// }

// export default Portfolio;
