import React from "react";
import Link from "react-router-dom/es/Link";

const Blogpost = ({
  title,
  date,
  desc_sort,
  descri,
  writername,
  image,
  id,
}) => {
  return (
    <div className="col-sm-12 col-md-4" style={{ marginBottom: "5%" }}>
      <div className="iq-blog-box">
        <div className="iq-blog-image clearfix">
          <img className="img-responsive center-block" src={image} alt="#" />
        </div>
        <div className="iq-blog-detail white-bg">
          <div className="blog-title">
            <h5 className="iq-tw-6">{title}</h5>
          </div>
          <div className="iq-blog-meta">
            <ul className="list-inline">
              <li>
                <a href="javascript:void(0)">
                  <i className="fa fa-calendar pr-2" aria-hidden="true" />{" "}
                  {date}
                </a>
              </li>
              <li />
            </ul>
          </div>
          <div className="blog-content">
            <div
              className="post__content"
              dangerouslySetInnerHTML={{ __html: desc_sort }}
            />
          </div>
          <div className="blog-button">
            <a href="javascript:void(0)" className="pull-left iq-tw-6 iq-user">
              <i className="fa fa-user-circle" aria-hidden="true" />
              {writername}
            </a>
            <Link to={"/blog-details/" + id} className="pull-right iq-tw-6">
              Read More <i className="fa fa-angle-right" aria-hidden="true" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogpost;
