import React, { Component } from "react";
import FlatHeader from "../common/FlatHeader";
import ReactGA from "react-ga";
import service_web from "../../assets/images/banner/Web-Developement.png";
import service_mobile from "../../assets/images/banner/services1.png";
import service_software from "../../assets/images/banner/Software-Solution.png";
import service_branding from "../../assets/images/banner/Branding.png";
import service_digital from "../../assets/images/banner/Digital-Marketing.png";
import ReactWOW from "react-wow";

// import axios from "axios";
// import ServiceLeft from "../common/ServiceLeft";
// import ServiceRight from "../common/ServicesRight";

// import WOW from "wowjs";

class Services extends Component {
  // constructor() {
  //     super();
  //     this.state = {
  //         services: null,
  //         iosX: 0
  //     };
  // }
  //
  // componentDidMount() {
  //     axios.get('https://api.vytech.co/ws/get-services')
  //         .then(res => this.setState({services: res.data.data})).catch(err => console.log(err));
  // }
  componentDidMount() {
    ReactGA.initialize("UA-109059503-2");
    ReactGA.pageview("/services");
    window.scrollTo(0, 0);
  }

  render() {
    // const {services} = this.state;

    return (
      <div>
        <FlatHeader title="Services" />

        {/*{services !== null ? services.map((service, index) => {*/}

        {/*if (index % 2 === 0) {*/}
        {/*return(*/}
        {/*<ServiceLeft key={index} name={service.name} image={service.image}*/}
        {/*short_description={service.short_description}/>*/}
        {/*)*/}
        {/*} else {*/}
        {/*return(*/}
        {/*<ServiceRight key={index} name={service.name} image={service.image}*/}
        {/*short_description={service.short_description}*/}
        {/*/>*/}
        {/*)*/}
        {/*}*/}

        {/*}) : null}*/}

        <div className="main-content hidden-xs hidden-sm">
          <div style={{ paddingTop: "5%" }}>
            <ul className="work">
              <li>
                <figure>
                  <div>
                    <img src={service_web} />
                  </div>
                  <div>
                    <ReactWOW animation="fadeInRight">
                      <figcaption>
                        <div className="info name">
                          <br />
                          <br />
                          <p style={{ color: "#13abdb", fontSize: "2em" }}>
                            Web Development
                          </p>
                        </div>
                        <div style={{ fontSize: "1.2em" }}>
                          <br />
                          <p style={{ textAlign: "justify" }}>
                            When it comes to developing web applications for
                            leading global enterprises at supremely affordable
                            prices, we ensure that we do everything to create
                            websites that are attractive, effective and
                            responsive. Our custom-designed in-house methodology
                            helps us develop top-notch reliable IT solutions
                            that meet present and future demands.
                          </p>
                          <p>Statics</p>
                          <p>Dynamics</p>
                          <p>E-commerce</p>
                        </div>
                      </figcaption>
                    </ReactWOW>
                  </div>
                </figure>
              </li>
              <li>
                <figure>
                  <img src={service_mobile} />

                  <ReactWOW animation="fadeInRight">
                    <figcaption>
                      <div className="info name">
                        <br />
                        <br />
                        <p style={{ color: "#13abdb", fontSize: "2em" }}>
                          Mobile App Development{" "}
                        </p>
                      </div>
                      <div style={{ fontSize: "1.2em" }}>
                        <br />
                        <p style={{ textAlign: "justify" }}>
                          With over 60+ mobile apps developed, we offer robust
                          and scalable mobile application development service
                          for Android, iOS and Windows for all your business
                          needs.
                        </p>

                        <p style={{ textAlign: "justify" }}>
                          {" "}
                          Since Mobile Applications play a vital role in any
                          businesses this days we create this apps with utmost
                          simplicity, robust code infrastructure and elegant
                          User Interface and User Experience.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          {" "}
                          Making a Custom design, focusing on key functionality
                          and delivering the App to the users in the most
                          effective time is our key focus.
                        </p>
                        <p>Android</p>
                        <p>IOS</p>
                        <p>E-commerce</p>
                      </div>
                    </figcaption>
                  </ReactWOW>
                </figure>
              </li>
              <li>
                <figure>
                  <img src={service_software} />

                  <ReactWOW animation="fadeInRight">
                    <figcaption className="wow fadeInRight">
                      <div className="info name">
                        <br />
                        <br />
                        <p style={{ color: "#13abdb", fontSize: "2em" }}>
                          Software Solution
                        </p>
                      </div>
                      <div style={{ fontSize: "1.2em" }}>
                        <br />
                        <p style={{ textAlign: "justify" }}>
                          VYTECH ENTERPRISE houses one of the most experienced
                          experts in different domains such as research,
                          business analysis, project management, programming,
                          database administration, creative designing and
                          quality control to ensure that you get the perfect
                          software solution for your business.
                        </p>
                      </div>
                    </figcaption>
                  </ReactWOW>
                </figure>
              </li>
              <li>
                <figure>
                  <img src={service_branding} />

                  <ReactWOW animation="fadeInLeft">
                    <figcaption>
                      <div className="info name">
                        <br />
                        <br />
                        <p style={{ color: "#13abdb", fontSize: "2em" }}>
                          Branding
                        </p>
                      </div>
                      <div style={{ fontSize: "1.2em" }}>
                        <br />
                        <p style={{ textAlign: "justify" }}>
                          At VyTech, we endeavour to provide comprehensive
                          branding solutions from consulting and designing brand
                          identity to developing a core strategy and creating
                          meaningful brand stories. We specialize in:
                        </p>
                        <p>Logo Design</p>
                        <p>Brochure</p>
                        <p>Product</p>
                        <p>Corporate Advivews</p>
                      </div>
                    </figcaption>
                  </ReactWOW>
                </figure>
              </li>
              <li>
                <figure>
                  <img src={service_digital} />

                  <ReactWOW animation="fadeInLeft">
                    <figcaption>
                      <div className="info name">
                        <br />
                        <br />
                        <p style={{ color: "#13abdb", fontSize: "2em" }}>
                          Digital Marketing
                        </p>
                      </div>
                      <div style={{ fontSize: "1.2em" }}>
                        <br />
                        <p style={{ textAlign: "justify" }}>
                          Digital Marketing is the most effective and proven way
                          of marketing in todays world. With the help of Social
                          Media handles and other public domain websites its
                          very easy to push your Products and Services in the
                          market hassle-free.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          However, a proper planning, designing the
                          info-graphics, tagging them with appropriate hashtags,
                          identifying your key audience are some factors which
                          should be kept in mind while execution.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          {" "}
                          At vytech We have dedicated resources who are well
                          aware about the market aspects, product knowledge,
                          marketing strategy planning etc. who are always
                          thriving for bigger coverage.
                        </p>
                      </div>
                    </figcaption>
                  </ReactWOW>
                </figure>
              </li>
            </ul>
          </div>
        </div>

        {/* ENd mobile layout*/}
        <hr />
        <div className="container">
          <div className="row iq-ptb-80">
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="iq-fancy-box-04">
                <div className="iq-icon green-bg">
                  <i aria-hidden="true" className="ion-ios-location-outline" />
                </div>
                <div className="fancy-content">
                  <h5 className="iq-tw-6">Address</h5>
                  <span className="lead iq-tw-6">
                    510, Addor Aspire, B/s Jhanvee Restaurant, Nr. Passport
                    Office, Polytechnic Rd, Ahmedabad, Gujarat 380015
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="iq-fancy-box-04">
                <div className="iq-icon green-bg">
                  <i aria-hidden="true" className="ion-ios-telephone-outline" />
                </div>
                <div className="fancy-content">
                  <h5 className="iq-tw-6">Phone</h5>
                  <span className="lead iq-tw-6">+91 7926300900</span>
                  <p className="iq-mb-0">Mon-Sat 9:30am - 6:30pm</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="iq-fancy-box-04">
                <div className="iq-icon green-bg">
                  <i aria-hidden="true" className="ion-ios-email-outline" />
                </div>
                <div className="fancy-content">
                  <h5 className="iq-tw-6">Mail</h5>
                  <span className="lead iq-tw-6">
                    <a
                      href="https://iqonicthemes.com/cdn-cgi/l/email-protection"
                      className="__cf_email__"
                      data-cfemail="e68b878f8aa68796968f8889c885898b"
                    >
                      info@vytechenterprise.com
                    </a>
                  </span>
                  <p className="iq-mb-0">24 X 7 online support</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
