import React, { Component } from "react";
import FlatHeader from "../common/FlatHeader";

// import blog01 from "../../assets/images/blog/01.jpg";
// import blog02 from "../../assets/images/blog/02.jpg";
// import blog03 from "../../assets/images/blog/03.jpg";
// import blog04 from "../../assets/images/blog/04.jpg";
// import blog05 from "../../assets/images/blog/05.jpg";
// import blog06 from "../../assets/images/blog/06.jpg";
// import blog07 from "../../assets/images/blog/07.jpg";
// import blog08 from "../../assets/images/blog/08.jpg";
// import blog09 from "../../assets/images/blog/09.jpg";
import axios from "axios";
import ReactGA from "react-ga";
import Blogpost from "../common/Blogpost";

class Blog extends Component {
  constructor() {
    ReactGA.initialize("UA-109059503-2");
    ReactGA.pageview("/blog");

    super();
    this.state = {
      blogdetails: null,
      iosX: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    axios
      .get("https://api.vytech.co/ws/get-blog-posts")
      .then(res => this.setState({ blogdetails: res.data.data }))
      .catch(err => console.log(err));
  }

  render() {
    const { blogdetails } = this.state;
    return (
      <div>
        <FlatHeader title="Blogs" />
        <section id="blog" className="iq-page-blog overview-block-ptb">
          <div className="container">
            <div className="row">
              {blogdetails !== null
                ? blogdetails.map((member, index) => (
                    <Blogpost
                      key={index}
                      title={member.title}
                      date={member.date}
                      image={member.image}
                      desc_sort={member.description_short}
                      writername={member.writer_name}
                      id={member.blog_id}
                    />
                  ))
                : null}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Blog;
