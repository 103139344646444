import React, { Component } from "react";
import "./methodology.css";
import Plx from "react-plx";
import FlatHeader from "../common/FlatHeader";
import Link from "react-router-dom/es/Link";
import icons1 from "../../assets/images/icons/icons1blue.png";
import icons2 from "../../assets/images/icons/icons2blue.png";
import icons3 from "../../assets/images/icons/icons3blue.png";
import icons4 from "../../assets/images/icons/icons4blue.png";
import icons5 from "../../assets/images/icons/icons5blue.png";
import icons6 from "../../assets/images/icons/icons6blue.png";
import icons7 from "../../assets/images/icons/icons7blue.png";
import icons8 from "../../assets/images/icons/icons8blue.png";
import ReactGA from "react-ga";

class Methodology extends Component {
  componentDidMount() {
    ReactGA.initialize("UA-109059503-2");
    ReactGA.pageview("/methodology");
    window.scrollTo(0, 0);
  }
  render() {
    // An array of parallax effects to be applied - see below for detail
    const enquiryParallaxData = [
      {
        start: 0,
        duration: 300,
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: "scale"
          }
        ]
      },
      {
        start: 15,
        duration: 300,
        properties: [
          {
            startValue: 300,
            endValue: 0,
            property: "translateX"
          }
        ]
      }
    ];

    const proposalParallaxData = [
      {
        start: 0,
        duration: 600,
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: "scale"
          }
        ]
      },
      {
        start: 30,
        duration: 600,
        properties: [
          {
            startValue: -980,
            endValue: 0,
            property: "translateX"
          }
        ]
      }
    ];

    const specificationParallaxData = [
      {
        start: 0,
        duration: 600,
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: "scale"
          }
        ]
      },
      {
        start: 60,
        duration: 600,
        properties: [
          {
            startValue: 700,
            endValue: 0,
            property: "translateX"
          }
        ]
      }
    ];

    const designParallaxData = [
      {
        start: 0,
        duration: 600,
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: "scale"
          }
        ]
      },
      {
        start: 200,
        duration: 700,
        properties: [
          {
            startValue: -980,
            endValue: 0,
            property: "translateX"
          }
        ]
      }
    ];

    const developmentParallaxData = [
      {
        start: 0,
        duration: 600,
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: "scale"
          }
        ]
      },
      {
        start: 600,
        duration: 700,
        properties: [
          {
            startValue: 700,
            endValue: 0,
            property: "translateX"
          }
        ]
      }
    ];

    const testingParallaxData = [
      {
        start: 0,
        duration: 600,
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: "scale"
          }
        ]
      },
      {
        start: 700,
        duration: 700,
        properties: [
          {
            startValue: -980,
            endValue: 0,
            property: "translateX"
          }
        ]
      }
    ];

    const goLiveParallaxData = [
      {
        start: 0,
        duration: 600,
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: "scale"
          }
        ]
      },
      {
        start: 1000,
        duration: 700,
        properties: [
          {
            startValue: 700,
            endValue: 0,
            property: "translateX"
          }
        ]
      }
    ];

    const reviewParallaxData = [
      {
        start: 0,
        duration: 600,
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: "scale"
          }
        ]
      },
      {
        start: 1100,
        duration: 600,
        properties: [
          {
            startValue: -980,
            endValue: 0,
            property: "translateX"
          }
        ]
      }
    ];

    return (
      <div>
        <FlatHeader title="Methodology" />
        <section id="about-us" className="overview-block-ptb iq-mt-50">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-timeline">
                  <Plx className="timeline" parallaxData={enquiryParallaxData}>
                    <div className="timeline-icon">
                      <span className="year">
                        <img src={icons8} />
                      </span>
                    </div>
                    <div
                      className="timeline-content"
                      style={{ backgroundColor: "rgba(9, 179, 239, 0.9)" }}
                    >
                      <h3 className="title">Enquiry</h3>
                      <p className="description">
                        It all starts when we get that first call or email from
                        you. We are very friendly and approachable.
                        {/* <Link to="" style={{ color: "#fff", marginLeft: "5%" }}>
                          Read More >>
                        </Link> */}
                      </p>
                    </div>
                  </Plx>

                  <Plx className="timeline" parallaxData={proposalParallaxData}>
                    <div className="timeline-icon">
                      <span className="year">
                        {" "}
                        <img src={icons6} />
                      </span>
                    </div>
                    <div className="timeline-content">
                      <h3 className="title">
                        Initial Proposal &amp; Guideline Prices
                      </h3>
                      <p className="description">
                        We’ve been doing this for a while so chances are we’ve
                        previously built a website similar to what you’re after
                        {/* <Link to="" style={{ color: "#fff", marginLeft: "5%" }}>
                          Read More >>
                        </Link> */}
                      </p>
                    </div>
                  </Plx>

                  <Plx
                    className="timeline"
                    parallaxData={specificationParallaxData}
                  >
                    <div className="timeline-icon">
                      <span className="year">
                        {" "}
                        <img src={icons5} />
                      </span>
                    </div>
                    <div className="timeline-content">
                      <h3 className="title">Project specification</h3>
                      <p className="description">
                        At this stage we’ll identify the results you are looking
                        to achieve and discuss how your website web application
                        will deliver them.
                        {/* <Link to="" style={{ color: "#fff", marginLeft: "5%" }}>
                          Read More >>
                        </Link> */}
                      </p>
                    </div>
                  </Plx>

                  <Plx className="timeline" parallaxData={designParallaxData}>
                    <div className="timeline-icon">
                      <span className="year">
                        {" "}
                        <img src={icons4} />
                      </span>
                    </div>
                    <div className="timeline-content">
                      <h3 className="title">Design Phase</h3>
                      <p className="description">
                        We will produce provisional designs to convey the
                        general look and feel for your website.Often there are a
                        number of rounds of feedback...
                        {/* <Link to="" style={{ color: "#fff", marginLeft: "5%" }}>
                          Read More >>
                        </Link> */}
                      </p>
                    </div>
                  </Plx>

                  <Plx
                    className="timeline"
                    parallaxData={developmentParallaxData}
                  >
                    <div className="timeline-icon">
                      <span className="year">
                        {" "}
                        <img src={icons3} />
                      </span>
                    </div>
                    <div className="timeline-content">
                      <h3 className="title">Development Phase</h3>
                      <p className="description">
                        We like to work with open source server-side
                        technologies like React, PHP, .NET, Yii2 and many other.
                      </p>
                    </div>
                  </Plx>

                  <Plx className="timeline" parallaxData={testingParallaxData}>
                    <div className="timeline-icon">
                      <span className="year">
                        {" "}
                        <img src={icons2} />
                      </span>
                    </div>
                    <div className="timeline-content">
                      <h3 className="title">Testing, feedback &amp; amends</h3>
                      <p className="description">
                        Once we have an initial implementation to show you we
                        will make it available for you to test online before it
                        is visible to the public.
                      </p>
                    </div>
                  </Plx>

                  <Plx className="timeline" parallaxData={goLiveParallaxData}>
                    <div className="timeline-icon">
                      <span className="year">
                        {" "}
                        <img src={icons7} />
                      </span>
                    </div>
                    <div className="timeline-content">
                      <h3 className="title">Go Live!</h3>
                      <p className="description">
                        The website (or application) is finished and ready to be
                        launched — All that remains is to hit the button!
                      </p>
                    </div>
                  </Plx>

                  <Plx className="timeline" parallaxData={reviewParallaxData}>
                    <div className="timeline-icon">
                      <span className="year">
                        {" "}
                        <img src={icons1} />
                      </span>
                    </div>
                    <div className="timeline-content">
                      <h3 className="title">Review, analyse &amp; improve</h3>
                      <p className="description">
                        All websites have a ‘bedding in’ period shortly after
                        they are launched and during this time we will monitor
                        your website.
                        {/* <Link to="" style={{ color: "#fff", marginLeft: "5%" }}>
                          Read More >>
                        </Link> */}
                      </p>
                    </div>
                  </Plx>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />
        <div className="container">
          <div className="row iq-ptb-80">
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="iq-fancy-box-04">
                <div className="iq-icon green-bg">
                  <i aria-hidden="true" className="ion-ios-location-outline" />
                </div>
                <div className="fancy-content">
                  <h5 className="iq-tw-6">Address</h5>
                  <span className="lead iq-tw-6">
                    510, Addor Aspire, B/s Jhanvee Restaurant, Nr. Passport
                    Office, Polytechnic Rd, Ahmedabad, Gujarat 380015
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="iq-fancy-box-04">
                <div className="iq-icon green-bg">
                  <i aria-hidden="true" className="ion-ios-telephone-outline" />
                </div>
                <div className="fancy-content">
                  <h5 className="iq-tw-6">Phone</h5>
                  <span className="lead iq-tw-6">+91 7926300900</span>
                  <p className="iq-mb-0">Mon-Sat 9:30am - 6:30pm</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="iq-fancy-box-04">
                <div className="iq-icon green-bg">
                  <i aria-hidden="true" className="ion-ios-email-outline" />
                </div>
                <div className="fancy-content">
                  <h5 className="iq-tw-6">Mail</h5>
                  <span className="lead iq-tw-6">
                    <a
                      href="https://iqonicthemes.com/cdn-cgi/l/email-protection"
                      className="__cf_email__"
                      data-cfemail="e68b878f8aa68796968f8889c885898b"
                    >
                      info@vytechenterprise.com
                    </a>
                  </span>
                  <p className="iq-mb-0">24 X 7 online support</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Methodology;
