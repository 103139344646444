import React, { Component } from "react";
import FlatHeader from "../common/FlatHeader";
import ReactGA from "react-ga";
import axios from "axios";

class BlogDesc extends Component {
  constructor() {
    ReactGA.initialize("UA-109059503-2");
    ReactGA.pageview("/blog-details");
    super();
    this.state = {
      singlepost: null,
      iosX: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    axios
      .get(
        "https://api.vytech.co/ws/get-blog-post?blog_id=" +
          this.props.match.params.id
      )
      .then(res => this.setState({ singlepost: res.data.data }))
      .catch(err => console.log(err));
  }

  render() {
    const { singlepost } = this.state;
    return (
      <div>
        <FlatHeader title="Blog" />
        <section id="blog" className="iq-page-blog overview-block-ptb">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="blog-title">
                  <h2 className="iq-tw-6">
                    {singlepost !== null ? singlepost.title : null}
                  </h2>{" "}
                  <br />
                </div>
                <div className="iq-blog-box">
                  <div className="iq-blog-image clearfix">
                    <img
                      className="img-responsive center-block"
                      src={singlepost !== null ? singlepost.image : null}
                      alt="#"
                    />
                  </div>
                  <div className="iq-blog-detail">
                    <div className="iq-blog-meta">
                      <ul className="list-inline">
                        <li>
                          <i className="fa fa-calendar" aria-hidden="true" />{" "}
                          {singlepost !== null ? singlepost.date : null}
                        </li>
                        {/* <li><a href="javascript:void(0)"><i class="fa fa-comment-o" aria-hidden="true"></i> 5</a></li> */}
                      </ul>
                    </div>
                    <div className="blog-content">
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              singlepost !== null
                                ? singlepost.description
                                : null
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default BlogDesc;
