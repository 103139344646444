import React, { Component } from "react";
import banner_bg from "../../assets/images/banner/bg.jpg";
import whoweare from "../../assets/images/banner/whoweare1.png";

import uiux from "../../assets/images/screenshots/specialservice1.png";
import mobiledev from "../../assets/images/screenshots/specialservice2.png";
import softsolu from "../../assets/images/screenshots/specialservice3.png";
import webdev from "../../assets/images/screenshots/specialservice4.png";
import graphdes from "../../assets/images/screenshots/specialservice5.png";
import digimar from "../../assets/images/screenshots/specialservice6.png";

import screenshot_08 from "../../assets/images/screenshots/08.png";
import screenshot_09 from "../../assets/images/screenshots/09.png";
import screenshot_16 from "../../assets/images/screenshots/16.png";
import screenshot_14 from "../../assets/images/screenshots/14.png";
import screenshot_12 from "../../assets/images/screenshots/12.png";
import screenshot_13 from "../../assets/images/screenshots/13.png";
import testimonial_01 from "../../assets/images/testimonial/01.jpg";
import testimonial_02 from "../../assets/images/testimonial/02.jpg";
import testimonial_03 from "../../assets/images/testimonial/03.jpg";
import testimonial_itw from "../../assets/images/testimonial/testimonial_itw.png";

import client_img1 from "../../assets/images/clients/vytechclient1.png";
import client_img2 from "../../assets/images/clients/vytechclient2.png";
import client_img3 from "../../assets/images/clients/vytechclient3.png";
import client_img4 from "../../assets/images/clients/vytechclient4.png";
import client_img5 from "../../assets/images/clients/vytechclient5.png";
import client_img6 from "../../assets/images/clients/vytechclient6.png";
import client_img7 from "../../assets/images/clients/vytechclient7.png";
import client_img8 from "../../assets/images/clients/vytechclient8.png";
import client_img9 from "../../assets/images/clients/vytechclient9.png";
import client_img10 from "../../assets/images/clients/vytechclient10.png";
import client_img11 from "../../assets/images/clients/vytechclient11.png";
import client_img12 from "../../assets/images/clients/vytechclient12.png";
import client_img13 from "../../assets/images/clients/vytechclient13.png";
import client_img14 from "../../assets/images/clients/vytechclient14.png";
import client_img15 from "../../assets/images/clients/vytechclient15.png";
import client_img16 from "../../assets/images/clients/vytechclient16.png";
import client_img17 from "../../assets/images/clients/vytechclient17.png";
import client_img18 from "../../assets/images/clients/vytechclient18.png";
import client_img19 from "../../assets/images/clients/vytechclient19.png";
import client_img20 from "../../assets/images/clients/vytechclient20.png";
import client_img21 from "../../assets/images/clients/vytechclient21.png";
import client_img22 from "../../assets/images/clients/vytechclient22.png";

import icons1 from "../../assets/images/icons/icons1.png";
import icons2 from "../../assets/images/icons/icons2.png";
import icons3 from "../../assets/images/icons/icons3.png";
import icons4 from "../../assets/images/icons/icons4.png";
import icons5 from "../../assets/images/icons/icons5.png";
import icons6 from "../../assets/images/icons/icons6.png";
import icons7 from "../../assets/images/icons/icons7.png";
import icons8 from "../../assets/images/icons/icons8.png";
import axios from "axios";
import ReactGA from "react-ga";
import "../../assets/css/style.scss";
import HeroSection from "../common/HeroSection";
import Heading from "../common/Heading";
import Card from "../common/Card";
import Link from "react-router-dom/es/Link";
import TeamMember from "../common/TeamMember";
import Testimonial from "../common/Testimonial";
import Blogpost from "../common/Blogpost";

class Landing extends Component {
  constructor() {
    ReactGA.initialize("UA-109059503-2");
    ReactGA.pageview("/");
    super();
    this.state = {
      team: null,
      testimonialdetail: null,
      blogdetails: null,
      iosX: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    axios
      .get("https://api.vytech.co/ws/get-team")
      .then((res) => this.setState({ team: res.data.data }))
      .catch((err) => console.log(err));

    axios
      .get("https://api.vytech.co/ws/get-testimonials")
      .then((res) => this.setState({ testimonialdetail: res.data.data }))
      .catch((err) => console.log(err));

    axios
      .get("https://api.vytech.co/ws/get-blog-posts")
      .then((res) => this.setState({ blogdetails: res.data.data }))
      .catch((err) => console.log(err));
  }

  render() {
    const { team } = this.state;
    const { testimonialdetail } = this.state;
    const { blogdetails } = this.state;

    return (
      <div>
        <HeroSection />

        <div className="main-content">
          <section id="about-us" className="overview-block-ptb iq-mt-50">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <Heading
                    title="About Us"
                    subTitle="Vytech Enterprise is a complete IT Solutions provider. It provides full variety of IT solutions and services that incorporates all segments of customer requirements. A variety of IT professionals keep working for a wide range of innovations in a variety of disciplines and competencies."
                  />
                </div>
              </div>
              <div className="row">
                <Card
                  title="Web Development"
                  icon={
                    <i
                      aria-hidden="true"
                      className="ion-ios-pie-outline iq-font-blue"
                    />
                  }
                  subTitle="Website is a basic requirement in the current business era and having a website is very simple ,but having a website which represents your business professionally and impressively is really a very much important."
                />

                <Card
                  title="App Development"
                  icon={
                    <i
                      aria-hidden="true"
                      className="ion-ios-color-filter-outline iq-font-blue"
                    />
                  }
                  subTitle="VYTECH ENTERPRISE offers user interface design and development solutions in Web and Mobile. Our expert team of designers and developers built custom applications which are user-friendly and realistic."
                />

                <Card
                  title="Internet Marketing"
                  icon={
                    <i
                      aria-hidden="true"
                      className="ion-ios-gear-outline iq-font-blue"
                    />
                  }
                  subTitle="In a World of Innovation, When every single element is depended on Internet, We here at VYTECH ENTERPRISE, ensure a rigorous and phenomenal approach towards promoting you, your company or your Brand."
                />
              </div>
            </div>
          </section>
          <hr />

          <section
            id="about-us-01"
            className="overview-block-ptb iq-about-1 grey-bg"
          >
            <div className="container">
              <div className="row iq-mt-20">
                <div className="col-sm-12 col-md-6 col-lg-6 iq-mt-30 popup-gallery">
                  <h2 className="heading-left iq-tw-6 iq-mt-40">Who we are</h2>
                  <p style={{ textAlign: "justify" }}>
                    We have a crystal clear objective to deliver quality IT
                    services and Software Development Services while
                    incorporating supreme value to enterprises globally at
                    affordable prices. In order to provide world class IT
                    solutions, we have tailored our in-house proven methodology,
                    which help us provide reliable IT solutions that meet
                    present as well as future demands. Our commitment to
                    regularly improve and better on our products sets us apart
                    from the rest.
                  </p>
                  <div
                    className="btn-group iq-mt-40"
                    role="group"
                    aria-label
                    style={{ marginBottom: "5%" }}
                  >
                    <Link className="button" to="/about">
                      Know More
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <img
                    className="img-responsive center-block"
                    src={whoweare}
                    alt="#"
                  />
                </div>
              </div>
            </div>
          </section>

          <section
            id="features"
            className="overview-block-ptb iq-amazing-tab white-bg"
          >
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="heading-title">
                    <h2 className="title iq-tw-6">Our Expertise</h2>
                    <div className="divider" />
                    <p>
                      The quality of any work depends on the sources and
                      infrastructure available with provides.Vytech houses 50+
                      well experienced experts in different domains such as
                      research, business analysis, project management,
                      programming, database administration, creative designing
                      and quality control!
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <ul className="nav nav-tabs" role="tablist">
                    <li
                      role="presentation"
                      className="active wow fadeInLeft"
                      data-wow-duration="1s"
                    >
                      <a
                        className="round-right"
                        href="#design"
                        aria-controls="design"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01 text-right">
                          <i aria-hidden="true" className="ion-ipad" />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            UI/UX Design
                          </h4>
                          <div className="fancy-content-01">
                            <p
                              style={{
                                textAlign: "justify",
                              }}
                            >
                              Innovation and user friendliness always walks Hand
                              in Hand and so does UI/UX.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className="wow fadeInLeft"
                      data-wow-duration="1s"
                    >
                      <a
                        className="round-right"
                        href="#resolution"
                        aria-controls="resolution"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01 text-right">
                          <i
                            aria-hidden="true"
                            className="ion-android-desktop"
                          />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            Web Development
                          </h4>
                          <div className="fancy-content-01">
                            <p style={{ textAlign: "justify" }}>
                              In today’s world your website is your new store,
                              and we know how to make it magnificent and eye
                              catching.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className="wow fadeInLeft"
                      data-wow-duration="1s"
                    >
                      <a
                        className="round-right"
                        href="#ready"
                        aria-controls="ready"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01 text-right">
                          <i
                            aria-hidden="true"
                            className="ion-android-phone-portrait"
                          />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            Mobile App Development
                          </h4>
                          <div className="fancy-content-01">
                            <p style={{ textAlign: "justify" }}>
                              Mobile Apps have the power to ease your life with
                              just a Tap, hence its important to develop them
                              with minute detailing.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 text-center hidden-sm hidden-xs">
                  <div className="tab-content">
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="design"
                    >
                      <img
                        src={uiux}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                    <div role="tabpanel" className="tab-pane" id="resolution">
                      <img
                        src={webdev}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                    <div role="tabpanel" className="tab-pane" id="ready">
                      <img
                        src={mobiledev}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                    <div role="tabpanel" className="tab-pane" id="fertures">
                      <img
                        src={softsolu}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                    <div role="tabpanel" className="tab-pane" id="face">
                      <img
                        src={graphdes}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                    <div role="tabpanel" className="tab-pane" id="codes">
                      <img
                        src={digimar}
                        className="img-responsive center-block"
                        alt="#"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <ul className="nav nav-tabs" role="tablist">
                    <li
                      role="presentation"
                      className="wow fadeInRight"
                      data-wow-duration="1s"
                    >
                      <a
                        href="#fertures"
                        aria-controls="fertures"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01">
                          <i
                            aria-hidden="true"
                            className="ion-android-laptop"
                          />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            Software Solution
                          </h4>
                          <div className="fancy-content-01">
                            <p style={{ textAlign: "justify" }}>
                              We provide Software Solutions which runs faster.
                              costs less. And never breaks.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className="wow fadeInRight"
                      data-wow-duration="1s"
                    >
                      <a
                        href="#face"
                        aria-controls="face"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01">
                          <i aria-hidden="true" className="ion-android-star" />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            Graphic Designing
                          </h4>
                          <div className="fancy-content-01">
                            <p style={{ textAlign: "justify" }}>
                              A well Designed Graphic can speak more than 1000
                              words, and convey all its sense without any words.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className="wow fadeInRight"
                      data-wow-duration="1s"
                    >
                      <a
                        href="#codes"
                        aria-controls="codes"
                        role="tab"
                        data-toggle="tab"
                      >
                        <div className="iq-fancy-box-01">
                          <i aria-hidden="true" className="ion-contrast" />
                          <h4 className="iq-tw-6" style={{ fontSize: "1.4em" }}>
                            Digital Marketing
                          </h4>
                          <div className="fancy-content-01">
                            <p style={{ textAlign: "justify" }}>
                              Instead of one-way interruption,Digital Marketing
                              is about delivering useful content at just the
                              right moment that buyer needs it.
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section
            id="screenshots"
            className="iq-app iq-bg iq-bg-fixed iq-font-white"
            style={{ background: `url(${banner_bg})` }}
          >
            <div className="container-fluid">
              <div className="row row-eq-height">
                <div className="col-md-6 text-left iq-ptb-80 green-bg">
                  <div className="iq-app-info">
                    <h2 className="heading-left iq-font-white white iq-tw-6 ">
                      Mobile Apps
                    </h2>
                    {/* <div className="lead iq-tw-6 iq-mb-20">
                      Vytech is here with its best screenshot, from this photo
                      gallery you can get idea about this application.
                    </div>
                    <h4 className="iq-mt-50 iq-font-white iq-tw-6 iq-mb-15">
                      Awesome Design
                    </h4> */}
                    <p style={{ textAlign: "justify" }}>
                      There is a Mobile App for almost everything you may think
                      or want to Have. We at VYTECH completely understand and
                      analyse our client’s requirements so that we can shape
                      them up and bring their ideas of a Mobile Application to
                      Life. Here are the Few Applications which we have Created.
                      Our Mobile Apps are always with a simpler and attractive
                      design with a simplicity that a Non-Tech person can use it
                      without any hesitation.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 iq-app-screen iq-ptb-80">
                  <div className="home-screen-slide">
                    <div
                      className="owl-carousel popup-gallery"
                      data-autoplay="true"
                      data-loop="true"
                      data-nav="false"
                      data-dots="false"
                      data-items={3}
                      data-items-laptop={2}
                      data-items-tab={2}
                      data-items-mobile={1}
                      data-items-mobile-sm={1}
                      data-margin={15}
                    >
                      <div className="item">
                        <img
                          className="img-responsive"
                          src={screenshot_08}
                          alt="#"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-responsive"
                          src={screenshot_09}
                          alt="#"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-responsive"
                          src={screenshot_16}
                          alt="#"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-responsive"
                          src={screenshot_14}
                          alt="#"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-responsive"
                          src={screenshot_12}
                          alt="#"
                        />
                      </div>
                      <div className="item">
                        <img
                          className="img-responsive"
                          src={screenshot_13}
                          alt="#"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section id="screenshots" className="overview-block-ptb grey-bg">*/}
          {/*<div className="container-fluid" style={{opacity: '0.8'}}>*/}
          {/*<div className="row">*/}
          {/*<div className="col-sm-12">*/}
          {/*<div className="heading-title">*/}
          {/*<h2 className="title iq-tw-6">Laptop Screenshots</h2>*/}
          {/*<div className="divider"/>*/}
          {/*<p>Vytech is here with its best screenshot, from this photo gallery you can*/}
          {/*get idea about this application.</p>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="col-sm-12">*/}
          {/*<div className="dt-laptop-slider">*/}
          {/*<div className="laptop-screen">*/}
          {/*<img className="app-shot"*/}
          {/*src="https://icdn3.digitaltrends.com/image/unsplash-laptop-mac-open.jpg"*/}
          {/*alt/>*/}
          {/*<img className="app-shot"*/}
          {/*src="https://st3.depositphotos.com/4157265/19090/v/600/depositphotos_190903552-stock-video-black-male-hands-typing-on.jpg"*/}
          {/*alt/>*/}
          {/*<img className="app-shot"*/}
          {/*src="https://st.depositphotos.com/1017986/2668/i/950/depositphotos_26682239-stock-photo-man-hands-typing-on-keyboard.jpg"*/}
          {/*alt/>*/}
          {/*<img className="app-shot"*/}
          {/*src="https://www.geeky-gadgets.com/wp-content/uploads/2014/07/Kickflip.jpg"/>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</section> */}

          <section className="overview-block-ptb white-bg">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="heading-title">
                    <h2 className="title iq-tw-6">Work Methodology</h2>
                    <div className="divider" />
                    <p>
                      The Smarter You Work, the Faster you achieve your Goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="iq-fancy-box-02 iq-shadow text-center iq-mb-60">
                    <div className="iq-icon">
                      <img src={icons8} />
                    </div>
                    <div className="fancy-content">
                      <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">Enquiry</h5>
                      <p>
                        It all starts when we get that first call or email from
                        you. We are very friendly and approachable, and no
                        question or idea is dismissed out-of-hand.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="iq-fancy-box-02 iq-shadow text-center iq-mb-60">
                    <div className="iq-icon">
                      <img src={icons6} />
                    </div>
                    <div className="fancy-content">
                      <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">
                        Initial Proposal &amp; Guideling Prices
                      </h5>
                      <p>
                        We’ve been doing this for a while so chances are we’ve
                        previously built a website similar to what you’re after
                        and we’ll be able to use that as a guide in order to put
                        together an initial proposal and rough estimate.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="iq-fancy-box-02 iq-shadow text-center iq-mb-60">
                    <div className="iq-icon">
                      <img src={icons5} />
                    </div>
                    <div className="fancy-content">
                      <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">
                        Project Specification
                      </h5>
                      <p>
                        At this stage we’ll identify the results you are looking
                        to achieve and discuss how your website web application
                        will deliver them.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="iq-fancy-box-02 iq-shadow text-center">
                    <div className="iq-icon">
                      <img src={icons4} />
                    </div>
                    <div className="fancy-content">
                      <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">
                        Design Phase
                      </h5>
                      <p>
                        We will produce provisional designs to convey the
                        general look and feel for your website. Often there are
                        a number of rounds of feedback and tweaks before the
                        design is signed-off.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="iq-fancy-box-02 iq-shadow text-center iq-mb-60">
                    <div className="iq-icon">
                      <img src={icons3} />
                    </div>
                    <div className="fancy-content">
                      <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">
                        Develpoment Phase
                      </h5>
                      <p>
                        We like to work with open source server-side
                        technologies like React, PHP, .NET, Yii2 and many other.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="iq-fancy-box-02 iq-shadow text-center iq-mb-60">
                    <div className="iq-icon">
                      <img src={icons2} />
                    </div>
                    <div className="fancy-content">
                      <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">
                        Testing Feedback &amp; Amends
                      </h5>
                      <p>
                        Once we have an initial implementation to show you we
                        will make it available for you to test online before it
                        is visible to the public.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="iq-fancy-box-02 iq-shadow text-center iq-mb-60">
                    <div className="iq-icon">
                      <img src={icons7} />
                    </div>
                    <div className="fancy-content">
                      <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">Go Live!</h5>
                      <p>
                        The website (or application) is finished and ready to be
                        launched — All that remains is to hit the button!.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="iq-fancy-box-02 iq-shadow text-center">
                    <div className="iq-icon">
                      <img src={icons1} />
                    </div>
                    <div className="fancy-content">
                      <h5 className="iq-tw-6 iq-pt-20 iq-pb-10">
                        Review, Analyse &amp; Improve
                      </h5>
                      <p>
                        All websites have a ‘bedding in’ period shortly after
                        they are launched and during this time we will monitor
                        your website, fix any bugs and make the inevitable
                        tweaks and changes you require once you start using it
                        in earnest.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="overview-block-ptb grey-bg iq-loved-customers">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="heading-title">
                    <h2 className="title iq-tw-6">Loved By Our Customers</h2>
                    <div className="divider" />
                    <p>
                      Our Customers are Our Priority, here are some kind words
                      of appreciation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className="owl-carousel"
                    data-autoplay="true"
                    data-loop="true"
                    data-dots="false"
                    data-items={3}
                    data-items-laptop={2}
                    data-items-tab={2}
                    data-items-mobile={1}
                    data-items-mobile-sm={1}
                    data-margin={15}
                  >
                    {testimonialdetail !== null
                      ? testimonialdetail.map((member, index) => (
                          <Testimonial
                            key={index}
                            name={member.name}
                            comment={member.comment}
                            image={member.image}
                          />
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <Testimonial testimonials={testimonialdetail} /> */}

          <section className="overview-block-ptb padding-b grey-bg iq-loved-customers">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="heading-title">
                    <h2 className="title iq-tw-6">Loved By Our Customers</h2>
                    <div className="divider" />
                    <p>
                      Our Customers are Our Priority, here are some kind words
                      of appreciation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className="owl-carousel"
                    data-autoplay="true"
                    data-loop="true"
                    data-dots="false"
                    data-items={3}
                    data-items-laptop={2}
                    data-items-tab={2}
                    data-items-mobile={1}
                    data-items-mobile-sm={1}
                    data-margin={15}
                  >
                    <div className="item">
                      <div className="iq-client white-bg">
                        <div className="client-img">
                          <img
                            alt="#"
                            className="img-responsive img-circle"
                            src={client_img19}
                          />
                        </div>
                        <div className="client-info">
                          <div className="client-name iq-mb-10">
                            <h5 className="iq-tw-6">Ritika Thadani</h5>
                            <span className="sub-title iq-tw-6">
                              Training Australia First
                            </span>
                          </div>
                          <p>
                            Vytech Enterprise did an amazing job! They are a
                            team of creative and hardworking web developers. My
                            website is easy to navigate and just what I wanted:
                            beautiful and professional!
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="iq-client white-bg">
                        <div className="client-img">
                          <img
                            alt="#"
                            className="img-responsive img-circle"
                            src={client_img5}
                          />
                        </div>
                        <div className="client-info">
                          <div className="client-name iq-mb-10">
                            <h5 className="iq-tw-6">Kashish Khan</h5>
                            <span className="sub-title iq-tw-6">
                              Kashish Khan Production
                            </span>
                          </div>
                          <p>
                            This is to bring out that I am thankful to you and
                            VYTECH ENTERPRISE for providing us with an excellent
                            service and personal attention in making our
                            company's website.I appreciate the time and effort
                            you delved in developing our website without any
                            hesitation. The website looks extremely fabulous and
                            has turned out to be better than what we had
                            expected.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="iq-client white-bg">
                        <div className="client-img">
                          <img
                            alt="#"
                            className="img-responsive img-circle"
                            src={client_img7}
                          />
                        </div>
                        <div className="client-info">
                          <div className="client-name iq-mb-10">
                            <h5 className="iq-tw-6">Honey Bansal</h5>
                            <span className="sub-title iq-tw-6">
                              M.D. of Shanti Ship Breakers Pvt. Ltd.
                            </span>
                          </div>
                          <p>
                            I came to Vytech to create a new website for my
                            company. After some initial discussions, we settled
                            on a few design concepts that I really liked.
                            However, the final website design just blew me away.
                            It was exactly what I imagined for my company.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="iq-client white-bg">
                        <div className="client-img">
                          <img
                            alt="#"
                            className="img-responsive"
                            src={testimonial_itw}
                          />
                        </div>
                        <div className="client-info">
                          <div className="client-name iq-mb-10">
                            <h5 className="iq-tw-6">
                              Ankur Sharma, ITW PlayWorx UK
                            </h5>
                          </div>
                          <p>
                            Fabulous set of professionals and timely delivery.
                            Timelines were really taken care of in a fast paced
                            project without compromising on the quality.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="item">
                      <div className="iq-client white-bg">
                        <div className="client-img">
                          <img
                            alt="#"
                            className="img-responsive img-circle"
                            src={testimonial_02}
                          />
                        </div>
                        <div className="client-info">
                          <div className="client-name iq-mb-10">
                            <h5 className="iq-tw-6">Steph Costas</h5>
                            
                          </div>
                          <p>
                            Fast, polite, professional help! Highly recommend!
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*   <section className="overview-block-ptb white-bg">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="heading-title">
                    <h2 className="title iq-tw-6">Meet the Team</h2>
                    <div className="divider" />
                    <p>
                      Every Member at Vytech Enterprise Is a Gem, have a look at
                      the Magnificent Team.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {team !== null
                  ? team && team.length > 0 && team.map((member, index) => (
                      <TeamMember
                        key={index}
                        name={member.name}
                        designation={member.designation}
                        profile_pic={member.image}
                        insta={member.insta_link}
                      />
                    ))
                  : null}
              </div>
            </div>
          </section> */}

          <section id="blog" className="overview-block-ptb grey-bg iq-blog">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="heading-title">
                    <h2 className="title iq-tw-6">Latest Blog Post</h2>
                    <div className="divider" />
                    <p>
                      As a Contributor to the IT Society, here are some of the
                      interesting facts and blogs by Our Team.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {blogdetails !== null
                  ? blogdetails.map((member, index) => {
                      if (index <= 2) {
                        return (
                          <Blogpost
                            key={index}
                            title={member.title}
                            date={member.date}
                            image={member.image}
                            desc_sort={member.description_short}
                            writername={member.writer_name}
                            id={member.blog_id}
                          />
                        );
                      }
                    })
                  : null}

                <div className="col-sm-12 text-center">
                  <Link className="button iq-mt-50" to="/blog">
                    Go To Blog
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <div className="iq-our-clients white-bg iq-ptb-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="heading-title">
                    <h2 className="title iq-tw-6">Our Valuable Clients</h2>
                    <div className="divider" />
                    <p>
                      Our customers are our priority, here are some kind words
                      of appreciation.
                    </p>
                  </div>
                  <div
                    className="owl-carousel"
                    data-autoplay="true"
                    data-loop="true"
                    data-dots="false"
                    data-items={5}
                    data-items-laptop={4}
                    data-items-tab={3}
                    data-items-mobile={2}
                    data-items-mobile-sm={1}
                  >
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img1}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img2}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img3}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img4}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img5}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img6}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img7}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img8}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img9}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img14}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img11}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%", paddingTop: "27%" }}
                        src={client_img12}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "90%" }}
                        src={client_img13}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img10}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%", paddingTop: "15%" }}
                        src={client_img15}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%" }}
                        src={client_img16}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%", paddingTop: "20%" }}
                        src={client_img17}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "70%" }}
                        src={client_img18}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ paddingTop: "10%" }}
                        src={client_img19}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%", paddingTop: "10%" }}
                        src={client_img20}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%", paddingTop: "16%" }}
                        src={client_img21}
                        alt="#"
                      />
                    </div>
                    <div className="item">
                      {" "}
                      <img
                        className="img-responsive "
                        style={{ width: "75%", paddingTop: "16%" }}
                        src={client_img22}
                        alt="#"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer">
            <div className="iq-ptb-60 green-bg iq-subscribe">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <form className="form-inline">
                      <div className="form-group">
                        <label htmlFor="email">Subscribe Our Newsletter</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Your Email"
                          id="email"
                        />
                      </div>
                      <a
                        href="javascript:void(0)"
                        className="button bt-white re-mt-30"
                      >
                        Subscribe Now
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <section id="contact-us" className="iq-full-contact white-bg">
              <div className="container-fluid">
                <div className="row no-gutter">
                  <div className="col-sm-5">
                    <div className="iq-map">
                      <iframe
                        width="100%"
                        height={600}
                        src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=510%2C%20Addor%20Aspire%2C%20B%2Fs%20Jhanvee%20Restaurant%2C%20Nr.%20Passport%20Office%2C%20Polytechnic%20Rd%2C%20University%20Area%2C%20Ahmedabad%2C%20Gujarat%20380015+(Vytech%20Enterprise)&ie=UTF8&t=k&z=17&iwloc=B&output=embed"
                        frameBorder={0}
                        scrolling="no"
                        marginHeight={0}
                        marginWidth={0}
                      />
                    </div>
                  </div>
                  <div className="col-sm-7 iq-mt-20 iq-pall-40">
                    <div>
                      <h4
                        className="heading-left iq-tw-6 iq-pb-20"
                        style={{ marginLeft: "10%" }}
                      >
                        Get in Touch
                      </h4>
                      <div className="row">
                        <div id="formmessage">
                          Success/Error Message Goes Here
                        </div>
                        <form
                          className="form-horizontal"
                          id="contactform"
                          method="post"
                          action="https://iqonicthemes.com/themes/appino/wave/php/contact-form.php"
                        >
                          <div className="contact-form">
                            <div className="col-sm-1" />
                            <div className="col-sm-5">
                              <div
                                className="section-field"
                                style={{ marginLeft: "2%" }}
                              >
                                <input
                                  id="name"
                                  type="text"
                                  placeholder="Name*"
                                  name="name"
                                />
                              </div>
                              <div
                                className="section-field"
                                style={{ marginLeft: "2%" }}
                              >
                                <input
                                  type="email"
                                  placeholder="Email*"
                                  name="email"
                                />
                              </div>
                              <div
                                className="section-field"
                                style={{ marginLeft: "2%" }}
                              >
                                <input
                                  type="text"
                                  placeholder="Website*"
                                  name="phone"
                                />
                              </div>

                              <div style={{ marginLeft: "2%" }}>
                                <select className="dropopt">
                                  <option selected>
                                    Select Your Interested Area
                                  </option>
                                  <option>Website Development</option>
                                  <option>
                                    Mobile Application Development
                                  </option>
                                  <option>Software Solutions</option>
                                  <option>Branding</option>
                                  <option>Digital Marketing</option>
                                </select>
                              </div>

                              <div
                                className="section-field"
                                style={{ marginLeft: "2%" }}
                              >
                                <input
                                  type="text"
                                  placeholder="Estimated Budget*"
                                  name="phone"
                                />
                              </div>
                            </div>
                            <div className="col-sm-5">
                              <div
                                className="section-field"
                                style={{ marginLeft: "2%" }}
                              >
                                <input
                                  type="text"
                                  placeholder="Phone*"
                                  name="phone"
                                />
                              </div>
                              <div
                                className="section-field"
                                style={{ marginLeft: "2%" }}
                              >
                                <input
                                  type="text"
                                  placeholder="Company Name*"
                                  name="phone"
                                />
                              </div>

                              <div
                                className="section-field textarea"
                                style={{ marginLeft: "2%" }}
                              >
                                <textarea
                                  className="input-message"
                                  placeholder="Message*"
                                  rows={7}
                                  name="message"
                                  defaultValue={""}
                                />
                              </div>
                              <input
                                type="hidden"
                                name="action"
                                defaultValue="sendEmail"
                              />
                              <button
                                id="submit"
                                name="submit"
                                type="submit"
                                value="Send"
                                className="button pull-right iq-mt-40"
                              >
                                Send Message
                              </button>
                            </div>
                            <div className="col-sm-1" />
                          </div>
                        </form>
                        <div id="ajaxloader" style={{ display: "none" }}>
                          <img
                            className="center-block mt-30 mb-30"
                            src="images/ajax-loader.html"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="container">
                <div className="row iq-ptb-80">
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="iq-fancy-box-04">
                      <div className="iq-icon green-bg">
                        <i
                          aria-hidden="true"
                          className="ion-ios-location-outline"
                        />
                      </div>
                      <div className="fancy-content">
                        <h5 className="iq-tw-6">Address</h5>
                        <span className="lead iq-tw-6">
                          510, Addor Aspire, B/s Jhanvee Restaurant, Nr.
                          Passport Office, Polytechnic Rd, Ahmedabad, Gujarat
                          380015
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="iq-fancy-box-04">
                      <div className="iq-icon green-bg">
                        <i
                          aria-hidden="true"
                          className="ion-ios-telephone-outline"
                        />
                      </div>
                      <div className="fancy-content">
                        <h5 className="iq-tw-6">Phone</h5>
                        <a href="tel:+919898888180">
                          <span className="lead iq-tw-6">+91 9898888180</span>
                        </a>
                        <p className="iq-mb-0">Mon-Sat 9:30am - 6:30pm</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="iq-fancy-box-04">
                      <div className="iq-icon green-bg">
                        <i
                          aria-hidden="true"
                          className="ion-ios-email-outline"
                        />
                      </div>
                      <div className="fancy-content">
                        <h5 className="iq-tw-6">Mail</h5>
                        <span className="lead iq-tw-6">
                          <a
                            href="mailto:sales@vytechenterprise.com"
                            // href="https://iqonicthemes.com/cdn-cgi/l/email-protection"
                            className="__cf_email__"
                            data-cfemail="e68b878f8aa68796968f8889c885898b"
                          >
                            sales@vytechenterprise.com
                          </a>
                        </span>
                        <p className="iq-mb-0">24 X 7 online support</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
