import React, { Component } from "react";
import logo from "../../assets/images/vytechl.png";
import banner_bg from "../../assets/images/banner/bg.jpg";
import img_02 from "../../assets/images/banner/02.png";
import img_04 from "../../assets/images/banner/04.png";
import img_05 from "../../assets/images/banner/05.png";
import img_06 from "../../assets/images/banner/06.png";
import img_07 from "../../assets/images/banner/07.png";
import img_08 from "../../assets/images/banner/08.png";
import Link from "react-router-dom/es/Link";
import { NavLink } from "react-router-dom";

class Navbar extends Component {
  render() {
    return (
      <div>
        <header id="header-wrap" data-spy="affix" data-offset-top={55}>
          <div className="row">
            <div className="container">
              <div className="col-sm-12">
                <nav className="navbar navbar-default">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#bs-example-navbar-collapse-1"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <Link className="navbar-brand" to="/">
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                  <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1"
                  >
                    <ul className="nav navbar-nav navbar-right" id="top-menu">
                      <li>
                        <NavLink to="/" activeClassName="active">
                          Home
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/about" activeClassName="active">
                          About Us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/methodology" activeClassName="active">
                          Methodology
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services" activeClassName="active">
                          Services
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/portfolio" activeClassName="active">
                          Portfolio
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/blog" activeClassName="active">
                          Blogs
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact" activeClassName="active">
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Navbar;
