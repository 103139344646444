import React from "react";

const Card = ({ title, icon, subTitle }) => {
  return (
    <div className="col-sm-12 col-md-4 col-lg-4">
      <div className="iq-fancy-box-03 text-left">
        {icon}
        <h4 className="iq-tw-6" style={{ fontSize: "1.5em!important" }}>
          {title}
        </h4>
        <div className="info-03">
          <p style={{ textAlign: "justify" }}>{subTitle}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
